import { useState, useRef } from 'react';

import { useClickOutside } from 'hooks/useClickOutside';

import { POSITION } from 'components/ToolkitV2/Tooltip/Tooltip.typed';
import type { SecondarySelectProps } from 'features/location/components/SecondarySelect/SecondarySelect.typed';
import { PAGE } from 'helpers/pages';

import { useLocationContext } from 'features/location/Location.context';
import { useRadiusSelect } from 'features/location/hooks/RadiusSelect/RadiusSelect.hook';

import * as Styled from 'features/location/components/SecondarySelect/SecondarySelect.styled';
import { RadiusTooltip } from 'features/location/components/SecondarySelect/SecondarySelect.styled';
import { Tooltip } from 'components/Toolkit/Tooltip/Tooltip';

// TODO: Temporary to support two different tooltips for the different pages this component is used on
// TooltipV2 (RadiusTooltip) is used on the search page, Tooltip is used on the home page.

type SearchPageRadiusTooltipProps = {
  displayInline?: boolean;
  heightVariantWithInline?: 'DEFAULT' | 'LARGE';
};

const SearchPageRadiusTooltip = (props: SearchPageRadiusTooltipProps) => {
  const { displayInline, heightVariantWithInline } = props;
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { radiusList, isExpanded } = useLocationContext();

  const [initialSecondarySelectOption] = radiusList;
  const IS_TOOLTIP_OPEN = shouldShowTooltip && !isExpanded;
  const close = () => setShouldShowTooltip(false);

  useClickOutside({ active: IS_TOOLTIP_OPEN, ref, onClickOutside: close });

  return (
    <RadiusTooltip
      headerText="Please select an area to enable search by distance"
      headerFontSize="M14"
      headerFontWeight="regular"
      isOpen={IS_TOOLTIP_OPEN}
      position={POSITION.BELOW}
      askToClose={() => setShouldShowTooltip(false)}
      ref={ref}
    >
      <Styled.DisabledSelect
        displayInline={displayInline}
        heightVariant={heightVariantWithInline}
        onClick={() => setShouldShowTooltip(true)}
      >
        <Styled.Text>{initialSecondarySelectOption?.label}</Styled.Text>
        <Styled.SIoChevronDownOutline height="1em" width="1em" />
      </Styled.DisabledSelect>
    </RadiusTooltip>
  );
};

function SecondarySelect(props: SecondarySelectProps) {
  const { displayInline, radiusCallback, heightVariant } = props;

  const heightVariantWithInline = heightVariant
    ? heightVariant
    : displayInline
    ? 'DEFAULT'
    : 'LARGE';

  const { radius, setRadius, radiusList, selectedCounty, countyTown, page } =
    useLocationContext();

  const [initialSecondarySelectOption] = radiusList;
  const isSecondarySelectDisabled = !countyTown;

  const updateRadius = radiusCallback ?? setRadius;

  const { handleRadiusChange } = useRadiusSelect({
    county: selectedCounty?.displayName,
    countyTown,
    setRadius: updateRadius,
    pushOnChange: page === PAGE.SEARCH,
  });

  return (
    <>
      {isSecondarySelectDisabled &&
        (page === PAGE.SEARCH ? (
          <SearchPageRadiusTooltip
            displayInline={displayInline}
            heightVariantWithInline={heightVariantWithInline}
          />
        ) : (
          <Tooltip
            subtitle="Please select an area to enable search by distance"
            arrowPosition={['right', 'right', 'right']}
          >
            <Styled.DisabledSelect
              displayInline={displayInline}
              heightVariant={heightVariantWithInline}
            >
              <Styled.Text>{initialSecondarySelectOption?.label}</Styled.Text>
              <Styled.SIoChevronDownOutline height="1em" width="1em" />
            </Styled.DisabledSelect>
          </Tooltip>
        ))}

      {!isSecondarySelectDisabled && (
        <Styled.SSelect
          name="radius"
          onChange={handleRadiusChange}
          disabled={isSecondarySelectDisabled}
          options={radiusList}
          selectStyles={
            !displayInline ? Styled.SecondarySelectStyles : undefined
          }
          heightVariant={heightVariantWithInline}
          value={radius}
          willUseSubText={false}
        />
      )}
    </>
  );
}

export { SecondarySelect };
