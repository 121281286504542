import styled from 'styled-components';

import { CloseOutlineIc } from '@dsch/dd-icons';

import { POSITION } from 'components/ToolkitV2/Tooltip/Tooltip.typed';
import type { Positions } from 'components/ToolkitV2/Tooltip/Tooltip.typed';
import { ThemeTypes } from 'types/Theme.namespace';

/*
 * The tooltip arrow is a square rotated 45 degrees and half-obscured by the tooltip.
 * This value determines the length of the sides of that square.
 */
const ARROW_LENGTH = '12px';

const Container = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.TOOLTIP};
`;

const CloseIcon = styled(CloseOutlineIc)`
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.S4};
`;

const HeaderText = styled.p<{
  fontSize: ThemeTypes.FontSize;
  fontWeight: ThemeTypes.FontWeight;
}>`
  ${({ theme, fontSize }) => theme.fontSize[fontSize]};
  font-weight: ${({ theme, fontWeight }) => theme.fontWeight[fontWeight]};
  color: ${({ theme }) => theme.colors.WHITE};
`;

const Copy = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.WHITE};
`;

const TooltipContainer = styled.div<{ position: Positions }>`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.DIALOG};
  top: ${({ position }) =>
    position === POSITION.BELOW ? `calc(100% + ${ARROW_LENGTH})` : ''};
  bottom: ${({ position }) =>
    position === POSITION.ABOVE ? `calc(100% + ${ARROW_LENGTH})` : ''};
`;

const TooltipBody = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const Arrow = styled.div<{
  position: Positions;
}>`
  position: absolute;
  height: ${ARROW_LENGTH};
  left: calc(50%);
  ${({ position }) =>
    position === POSITION.ABOVE
      ? `bottom: -${ARROW_LENGTH}`
      : `top: -${ARROW_LENGTH}`};

  &:after {
    content: '';
    height: ${ARROW_LENGTH};
    width: ${ARROW_LENGTH};
    position: absolute;
    top: ${({ position }) =>
      position === POSITION.ABOVE ? 'calc(-50%)' : 'calc(50%)'};
    left: calc(50%);
    background: ${({ theme }) => theme.colors.GREY_DARKER};
    transform: rotate(45deg);
  }
`;

export {
  Container,
  CloseIcon,
  Header,
  HeaderText,
  Copy,
  TooltipContainer,
  TooltipBody,
  Arrow,
};
