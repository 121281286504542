import type { ReactNode } from 'react';
import type { ThemeTypes } from 'types/Theme.namespace';

const POSITION = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
} as const;

type Positions = (typeof POSITION)[keyof typeof POSITION];

type TooltipProps = {
  isOpen: boolean;
  children: ReactNode;
  headerText?: string;
  headerFontSize?: ThemeTypes.FontSize;
  headerFontWeight?: ThemeTypes.FontWeight;
  copy?: string;
  askToClose?: () => void;
  position?: Positions;
  className?: string;
  dataTrackingAttribute?: string;
};

export { POSITION };
export type { TooltipProps, Positions };
