import styled, { css } from 'styled-components';

import { Tooltip } from 'components/ToolkitV2/Tooltip/Tooltip';
import { TooltipContainer } from 'components/ToolkitV2/Tooltip/Tooltip.styled';
import { Select } from 'components/Toolkit/Inputs/Select';

import { ChevronDownOutlineIc } from '@dsch/dd-icons';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';

const SSelect = styled(Select)`
  width: 100%;
`;

const RadiusTooltip = styled(Tooltip)`
  width: 100%;

  ${TooltipContainer} {
    width: 100%;
  }
`;

const SecondarySelectStyles = css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
`;

const DisabledSelect = styled.div<{
  displayInline?: boolean;
  heightVariant?: HeightVariant;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ heightVariant }) =>
    heightVariant === 'LARGE' ? '48px' : '40px'};
  padding: 0 ${({ theme }) => theme.spacing.M16};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  border-color: ${({ theme }) => theme.colors.GREY};
  border: 1px solid ${({ theme }) => theme.colors.GREY};
  border-radius: ${({ displayInline, theme }) =>
    displayInline
      ? theme.borderRadius.default
      : `0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0`};
  border-left: ${({ displayInline }) => (displayInline ? 1 : 0)};
  cursor: not-allowed;
`;

const Text = styled.p`
  ${({ theme }) => theme.fontSize.M16}
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;

const SIoChevronDownOutline = styled(ChevronDownOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
`;

export {
  SSelect,
  RadiusTooltip,
  SecondarySelectStyles,
  DisabledSelect,
  Text,
  SIoChevronDownOutline,
};
